<!--
 * @Description: 账号
 * 
 * @Author: XiongYaoYuan<xiongyaoyuan>
 * @Date: 2023-09-19 22:00:01
 * @FilePath: /JinChanTuoKe-Front/src/pages/views/user/account.vue
-->
<template>
  <div class="app-wrapper wrapper-view" style="height: 100%">
    <div class="wrapper-title">基础资料</div>
    <div class="account-list" v-if="info">
      <div class="cell">
        <div class="cell-label">用户姓名</div>
        <div>：</div>
        <div class="cell-value">{{ info.name }}</div>
      </div>
      <div class="cell">
        <div class="cell-label">套餐版本</div>
        <div>：</div>
        <div class="cell-value" v-if="info.product">
          <span
            :class="[
              'cell-tag',
              {
                'tag-warning': info.product.title.includes('高级'),
                'tag-primary': info.product.title.includes('旗舰'),
              },
            ]"
          >
            {{
              info.needBuyProduct === "no" ? info.product.title : "暂无可用套餐"
            }}
          </span>
        </div>
        <div class="cell-value" v-else>-</div>
      </div>
      <div class="cell">
        <div class="cell-label">失效时间</div>
        <div>：</div>
        <div class="cell-value" v-if="info.product">
          {{ info.product?.expired_at }}
        </div>
        <div class="cell-value" v-else>-</div>
      </div>
      <div class="cell">
        <div class="cell-label">联系电话</div>
        <div>：</div>
        <div class="cell-value">{{ info.mobile }}</div>
      </div>
      <div class="cell">
        <div class="cell-label">公司名称</div>
        <div>：</div>
        <div class="cell-value">{{ info.company }}</div>
      </div>
      <div class="cell">
        <div class="cell-label">公司简称</div>
        <div>：</div>
        <div class="cell-value">{{ info.company_nick_name }}</div>
      </div>      
      <div class="cell">
        <div class="cell-label">邮箱</div>
        <div>：</div>
        <div class="cell-value">{{ info.mailbox }}</div>
      </div>
      <div class="cell">
        <div class="cell-label">官网</div>
        <div>：</div>
        <div class="cell-value">{{ info.website }}</div>
      </div>
      <div class="cell">
        <div class="cell-label">公司行业</div>
        <div>：</div>
        <div class="cell-value">{{ info.company_industry }}</div>
      </div>
      <div class="cell">
        <div class="cell-label">公司简称</div>
        <div>：</div>
        <div class="cell-value">{{ info.company_shortname }}</div>
      </div>
      <div class="cell">
        <div class="cell-label">品牌名</div>
        <div>：</div>
        <div class="cell-value">{{ info.brand }}</div>
      </div>
      <div class="cell">
        <div class="cell-label">留资电话</div>
        <div>：</div>
        <div class="cell-value">{{ info.business_mobile }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { HTTP } from "../../../utils/request";

const URL = {
  info: "api/customer/user/info",
};

export default {
  data() {
    return {
      info: null,
    };
  },
  mounted() {
    this.getInfo();
  },
  methods: {
    async getInfo() {
      const res = await HTTP({
        url: URL.info,
        method: "get",
      });
      this.info = res;
    },
  },
};
</script>

<style lang="scss" scoped>
.account-list {
  margin-top: 55px;
  .cell {
    display: flex;
    color: #7c7f97;
    font-size: 14px;
    margin-bottom: 30px;
    .cell-label {
      width: 60px;
      text-align: justify;
      text-align-last: justify;
    }
    .cell-value {
      flex: 1;
      font-size: 14px;
      font-family: SourceHanSansCN-Regular-, SourceHanSansCN-Regular;
      font-weight: normal;
      color: #4e4e4e;
      margin-right: 20px;
    }
    .cell-tag {
      // width: 55px;
      padding: 2px 10px;
      background: #acc7e7;
      font-size: 12px;
      font-family: SourceHanSansCN-Regular-, SourceHanSansCN-Regular;
      font-weight: normal;
      color: #ffffff;
      border-radius: 4px;
      // display: flex;
      // align-items: center;
      // justify-content: center;
    }
  }
}
.tag-warning {
  background: #efa44b !important;
}

.tag-primary {
  background: #9f9ced !important;
}
</style>
